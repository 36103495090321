import React from "react"
import Layout from "../components/layout"
import DemoFooter from "../components/demoFooter"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import i1 from "../images/PSS-icon-06.png"
import i2 from "../images/PSS-icon-07.png"
import i3 from "../images/PSS-icon-08.png"
import SEO from "../components/seo"

export default function() {
  return (
    <Layout>
      <SEO title={'Support'}/>
      <div className={'row'}>
        <div className={"col-12"}>
          <div className={"row justify-content-center p-5 text-center"}>
            <div className={"col-md-4 mb-5"}>
              <img src={i1} className={"img-fluid w-50 mb-4"} alt={"Mobile Phone"}/>
              <p className={"lead mb-5"}>
                Monday - Friday: 9am - 6pm PST
              </p>
              <a href={"tel:+18006334374"} className={"btn btn-lg btn-secondary w-75"}>
                Call
              </a>
            </div>
            <div className={"col-md-4 mb-5"}>
              <img src={i2} className={"img-fluid w-50 mb-4"} alt={"Email Envelope"}/>
              <p className={"lead mb-5"}>24-Hours</p>
              <OutboundLink href={'mailto:info@powersportsupport.com'} className={"btn btn-lg btn-secondary w-75"}>
                Email
              </OutboundLink>
            </div>
            <div className={"col-md-4 mb-5"}>
              <img src={i3} className={"img-fluid w-50 mb-4"} alt={"Chat Bubbles"}/>
              <p className={"lead mb-5"}>Learn</p>
              <OutboundLink href={"https://help.powersportsupport.com"} className={"btn btn-lg btn-secondary w-75"}>Docs</OutboundLink>
            </div>
          </div>
        </div>
      </div>
      <DemoFooter/>
    </Layout>
  )
}
